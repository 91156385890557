@import "mixin";
@import "shared";

:root {
	--bodyBg: #271e42;
	--color: #ffffff;
	--headerColor: rgba(49, 49, 49, 0.39);
}

body {
	font-family: var(--font-poppins);
	font-weight: 400;
	max-width: 100vw;
	overflow-x: hidden;
	background-color: var(--bodyBg);
	color: #FFF;
}

h1, h2, h3, h4 {
	&.ant-typography {
		color: #FFFFFF;
	}
}

.site-layout-content {
	background: #fff;
	padding: 24px;
	min-height: 280px;
}

#components-layout-demo-top .logo {
	width: 120px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 24px 16px 0;
	float: left;
}

#__next {
	min-height: 100vh;
	display: flex;
}

.content-404 {
	margin-top: 20vh;
	text-align: center;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	.title-404 {
		margin: 0 20px 0 0;
		border-right: 2px solid #fff;
		padding-right: 20px;
		font-weight: 800;
		color: #fff;
	}
	button {
		@extend .btnFilled;
		padding: 10px 20px;
	}
}

.defHidden {
	opacity: 0;
}

.ant-select {
	color: #FFFFFF;
}

.ant-select-dropdown {
	background-color: #423072;
	box-shadow: 0 34px 80px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding: 8px 0;

	.rc-virtual-list-holder {
		@include scrollStyle(#cc78ff)
	}
}

.ant-select-item {
	background-color: #423072;
	color: #FFFFFF;

	&:hover:not(.ant-select-item-group) {
		background-color: rgba(#c278f8, 0.5);
	}

	&.ant-select-item-group {
		font-size: 15px;
		font-weight: bold;
	}
}

.ant-layout {
	background-color: #271e42;
	color: #FFFFFF;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled, :hover) {
	background-color: #423072;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #c278f8;
	font-weight: 400;
	color: #FFFFFF;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #CC78FF;
	border-width: 1px;
	box-shadow: 0 0 0 1px #CC78FF;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #271E41;
	border-radius: 4px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: #423072;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	color: #FFFFFF;
	display: none;
}

.ant-table-thead th.ant-table-column-sort {
	background-color: transparent;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background-color: transparent;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #CC78FF;
	border-color: #CC78FF;
}

.custom-toggle {
	position: relative;
	display: inline-flex;
	align-items: center;

	.holder,
	.switch {
		transition: .2s;
		will-change: transform;
	}
	input:checked + svg {
		.holder {
			fill: #CC78FF;
		}

		.switch {
			fill: #3B3060;
			transform: translateX(14px);
		}
	}
	input {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.ant-message-notice-content {
	background-color: #423072;

	.ant-message-custom-content {
		color: #FFFFFF;
	}
}

.ant-notification-notice {
	background-color: #423072;

	.ant-notification-notice-message,
	.ant-notification-notice-description,
	.ant-notification-notice-close {
		color: #FFFFFF;
	}
}


.ant-spin-dot .ant-spin-dot-item {
	background-color: #cc78ff;
}

.ant-switch {
	height: 12px;
	min-width: 29px;
	width: 29px;
	background-color: #3B3060;
	//filter: drop-shadow(1px 1px 3px rgb(204, 120, 255, 79%));

	&.ant-switch-checked {
		background-color: #CC78FF;
		//filter: drop-shadow(1px 1px 3px transparent);

		.ant-switch-handle {
			left: calc(100% - 8px - 3px);

			&:before {
				background-color: #3B3060;
			}
		}
	}

	.ant-switch-handle {
		height: 8px;
		width: 8px;
		left: 3px;

		&:before {
			background-color: #CC78FF;
		}
	}
}

.ant-input-password .ant-input-suffix {
	position: absolute;
	right: 5px;
	top: 2px;
	bottom: 2px;
	width: 20px;
	background-color: transparent !important;
}

@import "./global";
